import { CollectorCredentialsControllerApi } from "../open-api";
import { API } from "./context.service";

export type CredentialsUnion = Awaited<ReturnType<CollectorCredentialsControllerApi["getCleanCredentials"]>>;

export interface CollectorCredentialsService {
  get(collectorId: string): Promise<CredentialsUnion | null>;

  update(collectorId: string, dto: CredentialsUnion): Promise<void>;
}

export class LiveCollectorCredentialsService implements CollectorCredentialsService {
  constructor(private api: API) {}

  get = async (collectorId: string) => {
    try {
      return await this.api.collectorCredentials.getCleanCredentials({ collectorId });
      // @ts-ignore
    } catch (e: Error) {
      // TODO quick fix for CCC-1555, should be solved by service
      if (e.message.includes("Trying to access credentials from a portal collector")) {
        return Promise.resolve(null);
      } else {
        return Promise.reject(e);
      }
    }
  };

  /**
   * TODO: Fix this when
   *  https://github.com/OpenAPITools/openapi-generator/issues/7886
   *  is solved
   * @param collectorId
   * @param dto
   */
  update = (collectorId: string, dto: CredentialsUnion) => {
    return this.api.collectorCredentials.updateCredentials({
      collectorId,
      aWSCredentialsDTOCAConnectorCredentialsDTOCACredentialsDTOCSPCredentialsDTOGOOGLECredentialsDTOINGRAMCACredentialsDTOOCICredentialsDTO:
        dto,
    });
  };
}
