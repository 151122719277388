import {
  CollectorAws,
  CollectorAwsInput,
  CollectorAzure,
  CollectorAzureInput,
  CollectorConfigRequestDTO,
  CollectorConfigResponseDTO,
  CollectorOracle,
  CollectorOracleInput,
  CollectorUploadResponseDTO,
  CreateAwsCollectorParametersResponseDTO,
  CreateCollectorUploadRequestDTO,
  GoogleCollectorRequestDTO,
  GoogleCollectorResponseDTO,
  PipelineRun,
  PipelineRunEvent,
  UpdateCollectorRequestDTO,
  UploadCsvConfigResponseDTO,
  UploadCsvRequestDTO,
} from "../open-api";
import { useDataClient } from "../hooks/client.hooks";
import { deleteRequest, postRequest, putRequest } from "../utils/api.utils";
import { useIsGlobalAdmin } from "../admin/admin.hooks";

export const ISO_8601_DATE_FORMAT = "yyyy-MM-dd";

export type DataSourceResponseDTO =
  | CollectorAws
  | CollectorAzure
  | GoogleCollectorResponseDTO
  | CollectorUploadResponseDTO
  | CollectorOracle;

export enum DataSourceType {
  AWS = "AWS",
  AZURE = "AZURE",
  ORACLE = "ORACLE",
  GOOGLE = "GOOGLE",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  UPLOAD = "UPLOAD",
}

export enum CollectorStatus {
  CREATED = "CREATED",
  INITIALIZED = "INITIALIZED",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  MANAGED = "MANAGED",
  FAILED = "FAILED",
}

// TODO: Migrate to unions
export enum PipelineRunStatus {
  CREATED = "CREATED",
  SCHEDULED = "SCHEDULED",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",

  // DEPRECATED:
  INITIALIZED = "INITIALIZED",
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
  PAUSED = "PAUSED",
  NEW = "NEW",
  COLLECTING = "COLLECTING",
  WAITING = "WAITING",
  PROCESSING = "PROCESSING",
  NOTICE = "NOTICE",
  TIMEOUT = "TIMEOUT",
}

export enum CSVType {
  DATE = "DATE",
  NUMBER = "NUMBER",
  STRING = "STRING",
  CURRENCY = "CURRENCY",
}

export interface InvoicesCSVCollectorRequest {
  collectorId: string;
  secret: string;
  invoice: UploadCsvRequestDTO;
  file: File;
}

/**
 * usePipelineRUnEvents hook, used as follows:
 *
 * const [pipelineRunEvents, refreshPipelineRunEvents] = usePipelineRunEvents(collectorId, pipelineId))
 *
 * and to refresh:
 * refreshPipelineRunEvents()
 *
 * @param collectorId
 * @param pipelineId
 */
export const usePipelineRunEvents = (collectorId: string, pipelineId: string) => {
  const isAdmin = useIsGlobalAdmin();
  const url = isAdmin
    ? `/api/admin/datasources/events/${pipelineId}`
    : `/api/collectors/${collectorId}/pipelines/${pipelineId}/events`;

  return useDataClient<PipelineRunEvent[]>(url, {
    onSuccess: (res) => res.sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf()),
  });
};
export const usePipelineRuns = (collectorId: string) =>
  useDataClient<PipelineRun[]>(`/api/collectors/${collectorId}/pipelines`, {
    onSuccess: (res) => res.sort((a, b) => new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf()),
  });

export const useCollectors = () => useDataClient<DataSourceResponseDTO[]>(`/api/collectors`);
export const useCollector = (id: string) => useDataClient<DataSourceResponseDTO>(`/api/collectors/${id}`);

export const updateCollector = (id: string, collector: UpdateCollectorRequestDTO) =>
  putRequest(`/api/collectors/${id}`, collector);

/**
 * New create collector functions that use the postRequest method, can just be imported and used as:
 *
 * onSubmit={(input) => createAwsCollector(input)} in a form or something, or actually however you like.
 *
 * @param collector
 */
export const createAwsCollector = (collector: CollectorAwsInput) => postRequest("/api/collectors-aws", collector);

export const useAwsCollectorParameters = () =>
  useDataClient<CreateAwsCollectorParametersResponseDTO>("/api/collectors-aws/parameters");

export const createAzureCollector = (collector: CollectorAzureInput) => postRequest("/api/collectors-azure", collector);

export const createOracleCollector = (collector: CollectorOracleInput) =>
  postRequest("/api/collectors-oracle", collector);

export const createGoogleCollector = (collector: GoogleCollectorRequestDTO) =>
  postRequest("/api/collectors-google", collector);

export const createUploadCollector = (collector: CreateCollectorUploadRequestDTO) =>
  postRequest("/api/collectors-upload", collector);

export const deleteDataSource = (dataSourceId: string) => deleteRequest(`/api/collectors/${dataSourceId}`);

export const scheduleCollector = (collectorId: string) => postRequest(`/api/collectors/${collectorId}/schedule`, null);

export const useCollectorConfig = (collectorId: string) =>
  useDataClient<CollectorConfigResponseDTO>(`/api/collectors/${collectorId}/config`);

export const postCollectorConfig = (collectorId: string, collectorConfig: CollectorConfigRequestDTO) =>
  postRequest(`/api/collectors/${collectorId}/config`, collectorConfig);

export const useCollectorUpload = (collectorId: string) =>
  useDataClient<UploadCsvConfigResponseDTO>(`/api/collectors-upload/${collectorId}/config`);

export const csvUpload = (values: InvoicesCSVCollectorRequest) => {
  const formData = new FormData();
  const data = {
    ...values.invoice,
    secret: values.secret,
  };
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );
  formData.append("file", values.file, values.file.name);

  return postRequest(`/api/collectors-upload/${values.collectorId}/upload`, formData, {});
};

export const CA_CONSENT = {
  STATUS: {
    PENDING: "PENDING",
    VERIFYING: "VERIFYING",
    VERIFIED: "VERIFIED",
    FAILED: "FAILED",
  },
  STORAGE_STATUS: "connector.status",
  STORAGE_DOMAIN: "connector.adDomain",
  REDIRECT_PATH: "/azureconsent",
  READ_RIGHTS_FAQ_URL: "https://c-facts.atlassian.net/servicedesk/customer/portal/3/article/737214516",
  CLIENT_ID: "52f7f96a-a0e6-4223-9b92-9799200171be",
  redirectUrl: () => encodeURIComponent(`${window.location.origin}${CA_CONSENT.REDIRECT_PATH}`),
  redirectUrl2: () => encodeURIComponent(`${window.location.origin}${CA_CONSENT.REDIRECT_PATH}`),
  url1: () =>
    `https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=${
      CA_CONSENT.CLIENT_ID
    }&redirect_uri=${CA_CONSENT.redirectUrl()}&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default&state=121`,
  url2: (adDomain: string) =>
    `https://login.microsoftonline.com/${adDomain}/adminConsent?client_id=${
      CA_CONSENT.CLIENT_ID
    }&redirect_uri=${CA_CONSENT.redirectUrl2()}`,
};

export const CREATE_AWS_CONFIG = {
  TYPE_KEYS: {
    IAM_ROLE: "IAM_ROLE",
    IAM_USER: "IAM_USER",
  },
  REPORT_KEYS: {
    COST_EXPLORER: "COST_EXPLORER",
    COST_REPORT: "COST_REPORT",
  },
  TYPE_OPTIONS: [
    { value: "IAM_ROLE", label: "IAM Role (recommended)" },
    { value: "IAM_USER", label: "IAM User" },
  ],
  REPORT_OPTIONS: [
    { key: "COST_EXPLORER", label: "Use Cost Explorer" },
    { key: "COST_REPORT", label: "Use Cost Explorer with Cost Reports" },
  ],
};

export enum AZURE_ACCOUNT_TYPE {
  CA_EA_CONNECTOR = "CA_CONNECTOR",
  CA_EA = "CA",
  CSP = "CSP",
  INGRAMCA = "INGRAMCA",

  AZURE_INDIRECT_CONNECTOR = "AZURE_INDIRECT_CONNECTOR",
  AZURE_INDIRECT = "AZURE_INDIRECT",
}

export const AZURE_CONFIG_TYPES: Array<{ key: string; label: string }> = [
  {
    key: AZURE_ACCOUNT_TYPE.CA_EA_CONNECTOR,
    label: "CA/EA Connector (recommended)",
  },
  {
    key: AZURE_ACCOUNT_TYPE.CA_EA,
    label: "CA/EA",
  },
  {
    key: AZURE_ACCOUNT_TYPE.CSP,
    label: "CSP",
  },
  // CCC-1819
  // {
  //   key: AZURE_ACCOUNT_TYPE.AZURE_INDIRECT_CONNECTOR,
  //   label: "Indirect Azure Connector (recommended)",
  // },
  // {
  //   key: AZURE_ACCOUNT_TYPE.AZURE_INDIRECT,
  //   label: "Indirect Azure",
  // },
];

export const NUMBER_FORMAT_KEYS = {
  US: "US",
  EU: "EU",
  DEFAULT: "DEFAULT",
};

export const CSV_UPLOAD_CONFIG = {
  DELIMITER_OPTIONS: [
    {
      key: ",",
      label: ",",
    },
    {
      key: ";",
      label: ";",
    },
    {
      key: "\t",
      label: "tab",
    },
  ],
  DATE_FORMAT_OPTIONS: [
    {
      key: ISO_8601_DATE_FORMAT,
      label: "Default (2020-01-31)",
    },
    {
      key: "dd-MM-yyyy",
      label: "EU (31-01-2020)",
    },
    {
      key: "MM/dd/yyyy",
      label: "US (01/31/2020)",
    },
  ],
  NUMBER_FORMAT_OPTIONS: [
    {
      key: NUMBER_FORMAT_KEYS.DEFAULT,
      label: "Default (1234.56)",
    },
    {
      key: NUMBER_FORMAT_KEYS.EU,
      label: "EU (1.234,56)",
    },
    {
      key: NUMBER_FORMAT_KEYS.US,
      label: "US (1,234.56)",
    },
  ],
};
