/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AWSCredentialsDTO,
    ApiError,
    CAConnectorCredentialsDTO,
    CACredentialsDTO,
    CSPCredentialsDTO,
    GOOGLECredentialsDTO,
    INGRAMCACredentialsDTO,
    OCICredentialsDTO,
} from '../models';

export interface GetCleanCredentialsRequest {
    collectorId: string;
}

export interface UpdateCredentialsRequest {
    collectorId: string;
    aWSCredentialsDTOCAConnectorCredentialsDTOCACredentialsDTOCSPCredentialsDTOGOOGLECredentialsDTOINGRAMCACredentialsDTOOCICredentialsDTO: AWSCredentialsDTO | CAConnectorCredentialsDTO | CACredentialsDTO | CSPCredentialsDTO | GOOGLECredentialsDTO | INGRAMCACredentialsDTO | OCICredentialsDTO | null;
}

/**
 * 
 */
export class CollectorCredentialsControllerApi extends runtime.BaseAPI {

    /**
     */
    async getCleanCredentialsRaw(requestParameters: GetCleanCredentialsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AWSCredentialsDTO | CAConnectorCredentialsDTO | CACredentialsDTO | CSPCredentialsDTO | GOOGLECredentialsDTO | INGRAMCACredentialsDTO | OCICredentialsDTO>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling getCleanCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collectors/{collectorId}/credentials`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getCleanCredentials(requestParameters: GetCleanCredentialsRequest, initOverrides?: RequestInit): Promise<AWSCredentialsDTO | CAConnectorCredentialsDTO | CACredentialsDTO | CSPCredentialsDTO | GOOGLECredentialsDTO | INGRAMCACredentialsDTO | OCICredentialsDTO> {
        const response = await this.getCleanCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateCredentialsRaw(requestParameters: UpdateCredentialsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling updateCredentials.');
        }

        if (requestParameters.aWSCredentialsDTOCAConnectorCredentialsDTOCACredentialsDTOCSPCredentialsDTOGOOGLECredentialsDTOINGRAMCACredentialsDTOOCICredentialsDTO === null || requestParameters.aWSCredentialsDTOCAConnectorCredentialsDTOCACredentialsDTOCSPCredentialsDTOGOOGLECredentialsDTOINGRAMCACredentialsDTOOCICredentialsDTO === undefined) {
            throw new runtime.RequiredError('aWSCredentialsDTOCAConnectorCredentialsDTOCACredentialsDTOCSPCredentialsDTOGOOGLECredentialsDTOINGRAMCACredentialsDTOOCICredentialsDTO','Required parameter requestParameters.aWSCredentialsDTOCAConnectorCredentialsDTOCACredentialsDTOCSPCredentialsDTOGOOGLECredentialsDTOINGRAMCACredentialsDTOOCICredentialsDTO was null or undefined when calling updateCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collectors/{collectorId}/credentials`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.aWSCredentialsDTOCAConnectorCredentialsDTOCACredentialsDTOCSPCredentialsDTOGOOGLECredentialsDTOINGRAMCACredentialsDTOOCICredentialsDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCredentials(requestParameters: UpdateCredentialsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateCredentialsRaw(requestParameters, initOverrides);
    }

}
